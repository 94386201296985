import store from '@/store';
import config from '@/config';
import { getStore, setStore } from '@/libs/storage-utils';
import { Toast, Dialog } from 'vant';
import { getCityList} from '@api/permission-request'


export const cityList = getStore(config.const.KEY_CITY_LIST);

/**
 * 高德定位
 * @returns
 */
export function getAMapLocation() {
    return new Promise((resolve, reject) => {
        AMap.plugin("AMap.Geolocation", function() {
            let geolocation = new AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: true,
                // 设置定位超时时间，默认：无穷大
                timeout: 8000,
            });
            AMap.event.addListener(geolocation, "complete", onComplete);
            AMap.event.addListener(geolocation, "error", onError);
            geolocation.getCurrentPosition();
            // data是具体的定位信息
            function onComplete(data) {
                if (data) {
                    console.log("AMap -> 高精度定位信息", data);
                }
                if (data.position && data.position.lat && data.position.lng) {
                    console.log('AMap -> 经纬度:', data.position)
                    store.commit('setPosition', { lat: data.position.lat, lng: data.position.lng });
                }
                resolve(data.formattedAddress);
            }

            function onError(data) {
                console.log('AMap -> 高精度定位失败', data);
                // 失败 启用 ip定位
                AMap.plugin("AMap.CitySearch", function() {
                    let citySearch = new AMap.CitySearch();
                    citySearch.getLocalCity(function(status, result) {
                        if (status === "complete" && result.info === "OK") {
                            // 查询成功，result即为当前所在城市信息
                            console.log("AMap -> IP获取当前城市：", result);
                            if (result && result.position) {
                                store.commit('setPosition', result.position);
                            }
                            resolve(result.city);
                        } else {
                            reject(result.city);
                        }
                    });
                });
            }
        });
    });
}



function getQQMapLocation() {
    let geolocation = new qq.maps.Geolocation();
    return new Promise((resolve, reject) => {
        geolocation.getLocation((res) => {
            console.log('qqMap 高精度定位成功, -> ', res)
            if (res && res.lat && res.lng) {
                console.log(`qqMap 高精度定位经纬度 -> 经度${res.lng}, 纬度${res.lat}`)
                store.commit('setPosition', { lat: res.lat, lng: res.lng });
            }
            resolve(res.city);
        }, (err) => {
            console.log('qqMap 高精度定位失败 -> ', err);
            geolocation.getIpLocation((res) => {
                console.log('qqMap IP定位成功 -> ', res);
                if (res && res.lat && res.lng) {
                    console.log(`qqMap IP定位经纬度 -> 经度${res.lng}, 纬度${res.lat}`)
                    store.commit('setPosition', { lat: res.lat, lng: res.lng });
                }
                resolve(res.city)
            }, (err) => {
                console.log('qqMap IP定位失败 -> ', err);
                reject();
            })
        })
    })
}

/**
 * 获取用户地理位置
 * 后续添加wx定位
 */
export function getUserLocation() {
    // getQQMapLocation();
    // Toast.loading('定位中');
    return new Promise((resolve, reject) => {
        if (store.getters.positionFlag) {
            return reject();
        }
        // wxLocation({}).then((res) => {
        //   console.log('wx location res :>> ', res);
        // })
        console.log('map-util -> 开始获取用户定位');
        let promiseArray = [getQQMapLocation(), buildCityList()];
        Promise.all(promiseArray).then((result) => {
            store.commit('setPositionFlag', true);
            let aMapLocationStr = result[0];
            let cityList = result[1];
            matchCityCode(cityList, aMapLocationStr).then((res) => {
                store.commit('setLocation', res);
                let selectedLocation = store.state.app.selectedLocation;
                if (!selectedLocation) {
                    store.commit('setSelectedLocation', res);
                    resolve();
                    return;
                }
                if (selectedLocation && selectedLocation.name !== res.name) {
                    Dialog.confirm({
                        title: '切换城市',
                        message: `定位到您在${res.name}，是否切换?`
                    }).then(() => {
                        resolve(true);
                        store.commit('setSelectedLocation', res);
                    }).catch(() => {
                        resolve(true);
                    });
                } else {
                    resolve();
                }
            }).catch((err) => {
                console.log(err)
                reject();
            });
        })
    })
}

/**
 * 匹配定位的地址是在哪个城市
 * @param {后台城市库缓存} cityList
 * @param {*匹配地址} address
 * @returns
 */
function matchCityCode(cityList, address) {
    return new Promise((resolve, reject) => {
        let result = null;
        for (let item of cityList) {
            if (address.indexOf(item.name) > -1) {
                result = item;
                break;
            }
        }
        if (result) {
            resolve(result);
        } else {
            reject('城市CityCode匹配失败')
        }
    })
}

/**
 * 本地缓存 后台城市库
 * @returns
 */
export function buildCityList() {
    return new Promise((resolve, reject) => {
        // 无列表或者数组为空
        if (!cityList || cityList.length < 1) {
            getCityList().then((res) => {
                setStore(config.const.KEY_CITY_LIST, res.list);
                resolve(res.list);
            }).catch((err) => {
                reject(err);
            })
        } else {
            resolve(cityList);
        }
    })
}
